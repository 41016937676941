import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [

    // public pages
    {
        path: '',
        redirectTo: '',
        loadChildren: () => import('./public/public.module').then(module => module.PublicModule),
        pathMatch: 'full'
    },

    // auth pages
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(module => module.AuthModule)
    },

    // user secure pages
    {
        path: 'secure',
        loadChildren: () => import('./secure/secure.module').then(module => module.SecureModule)
    }

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            scrollOffset: [0, 64] // [x, y]
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
